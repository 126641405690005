import React from 'react';
import Layout from '../components/layout';

const Resume = () => {
    return(
      <Layout>Resume</Layout>  
    )
    
}

export default Resume;

